import React from 'react';
import SideMenu from '../SideMenu/SideMenu';
import { LayoutContainer, LayoutContent } from './Layout.style';
import TopBar from '../TopBar/TopBar';

const Layout = ({ children }) => (
  <LayoutContainer>
    <SideMenu />
    <LayoutContent><TopBar />{children}</LayoutContent>
  </LayoutContainer>
)


export default Layout;
