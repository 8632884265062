import React from 'react';
import { Pane, Dialog, Button } from 'evergreen-ui';
import { ButtonDashboard, FlexJustify, IconContainer } from '../styles/Shared';
import ReactLoading from 'react-loading';

const Footer = ({ onClose, onConfirm, loading }) => (
  <FlexJustify justify="center">
    <ButtonDashboard style={{ marginRight: 10 }} onClick={onClose}>
      Cancel
    </ButtonDashboard>
    <ButtonDashboard onClick={onConfirm} disabled={loading}>
      {loading ? (
        <ReactLoading type="spin" color="#fff" height={20} width={20} />
      ) : (
        'Confirm'
      )}
    </ButtonDashboard>
  </FlexJustify>
);

export default function Modal({
  confirmLabel,
  children,
  title,
  open,
  onClose,
  onConfirm,
  loading,
}) {
  return (
    <Pane>
      <Dialog
        isShown={open}
        title={title}
        onCloseComplete={onClose}
        confirmLabel={confirmLabel}
        footer={
          <Footer onClose={onClose} onConfirm={onConfirm} loading={loading} />
        }
      >
        {children}
      </Dialog>
    </Pane>
  );
}
