import { api } from './axios';

export const getChallengeLeaderboard = async (challengeId) => {
  try {
    const { data } = await api().get(`/challenges/${challengeId}/leaderboard`);
    return data;
  } catch (error) {
    console.log(error);
  }
};
