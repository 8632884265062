import React, { useState } from 'react';
import useGetChamps from '../../hooks/useGetChamps';
import { SearchChampContainer, SearchChampItems, SearchedItem, ImgChamp } from './SearchChamp.style';
import { InputField } from '../Input/Input.style';

const SearchChamp = ({ placeholder, onSelect, disabled }) => {
  const { champs } = useGetChamps();
  const [champions, setChampions] = useState([]);
  const [input, setInput] = useState('');

  const onChange = (e) => {
    const arrChamps = []
    Object.keys(champs).filter(champ => {
      if (champs[champ].name.toLowerCase().includes(e.target.value)) {
        arrChamps.push(champs[champ]);
      }
    });
    setInput(e.target.value);
    setChampions(arrChamps);
  }

  const onSelectChamp = (champ) => {
    onSelect(champ);
    setInput('');
    setChampions([]);
  }

  return (
    <SearchChampContainer>
      <InputField style={{ marginTop: 0 }} value={input} onChange={onChange} placeholder={placeholder} disabled={disabled} />
      {champions.length > 0 && <SearchChampItems>
        {champions.map(champ => (
          <SearchedItem
            onClick={() => onSelectChamp({ ...champ, champIcon: `https://ddragon.leagueoflegends.com/cdn/10.13.1/img/champion/${champ.id}.png` })}
            key={champ.name}>
            <ImgChamp
              src={`https://ddragon.leagueoflegends.com/cdn/10.13.1/img/champion/${champ.id}.png`} />
            {champ.name}
          </SearchedItem>))}
      </SearchChampItems>
      }
    </SearchChampContainer>
  )
};

export default SearchChamp;