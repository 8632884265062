import styled from 'styled-components';

export const RowWrapper = styled.tr`
  background: #151A30;
  > td {
    padding: 8px 5px;
    font-size: 1.2rem;
    font-weight: bold;
    color: #D8D8D8;
    border-bottom: 1px solid #2E3A59;
    text-align: center;
  }
`;

