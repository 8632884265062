import styled from 'styled-components';

export const Table = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  background-color: transparent;
  width: 100%;

  > thead {
    background: #2E3A59;

    font-size: 14px;
  }
  > tbody {
    width: 100%;
    border: none;
  }
  @media screen and (max-width: 768px) {
    width: 854px;
  }
`;

export const HeaderTr = styled.tr`
  color: #4c5180;
  font-size: 14px;
  box-sizing: border-box;
  border-top: 1px solid #303665;
  border-bottom: 1px solid #303665;

  > th {
    text-align: center;    
    color: #D8D8D8;
    letter-spacing: .5px;
    text-transform: capitalize;
    font-weight: bold;
    height: 40px;
    padding: 3px 0;
    width: 120px;
  }
`;
