import React, { useState } from 'react';
import useSummoners from '../../hooks/useSummoners';
import TableRow from '../../components/Tables/TableRow/TableRow';
import Tables from '../../components/Tables/Tables';
import moment from 'moment';
import {
  PageTitle,
  PageWrapper,
  FlexJustify,
  ButtonIcon,
  TdFlex,
  SummonerAvatar,
  IconContainer,
} from '../../styles/Shared';
import { FaFilter } from 'react-icons/fa';
import { IoMdTrash } from 'react-icons/io';
import { BsFillLockFill } from 'react-icons/bs';
import { blockUser, deleteSummoners } from '../../services/sumonners';
import Modal from '../../components/Modal';
import { toast } from 'react-toastify';

const tableKeys = ['#', 'Summoner Name', 'Register Date', 'Email', 'Actions'];

// const renderPages = (pages) => {
//   const pagesList = [];
//   for (let i = 1; i <= pages; i++) {
//     pagesList.push(i);
//   }
//   return pagesList
// }

const Users = () => {
  const { summoners, refreshSummoners } = useSummoners();
  const [loading, setLoading] = useState(false);
  const [loadingBlockUser, setLoadingBlockUser] = useState(false);

  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openBlockModal, setOpenBlockModal] = React.useState(false);
  const [selectedSummoner, setSelectedSummoner] = React.useState(null);

  const onDeleteUser = async () => {
    try {
      setLoading(true);
      await deleteSummoners(selectedSummoner);
      setOpenDeleteModal(false);
      refreshSummoners();
      toast.success('Summoner deleted successfully');
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const onBlockSummoner = async () => {
    try {
      setLoadingBlockUser(true);
      await blockUser(selectedSummoner);
      setOpenBlockModal(false);
      refreshSummoners();
      toast.success('Summoner blocked successfully');
    } catch (error) {
      console.log(error);
    }
    setLoadingBlockUser(false);
  };

  return (
    <PageWrapper>
      <Modal
        title={'Delete User'}
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onConfirm={() => onDeleteUser()}
        loading={loading}
      >
        <h3 style={{ fontSize: 16 }}>
          Are you sure you want to delete this summoner?
        </h3>
      </Modal>
      <Modal
        title={'Block User'}
        open={openBlockModal}
        onClose={() => setOpenBlockModal(false)}
        onConfirm={() => onBlockSummoner()}
        loading={loadingBlockUser}
      >
        <h3 style={{ fontSize: 16 }}>
          Are you sure you want to block this summoner?
        </h3>
      </Modal>
      <PageTitle>Users</PageTitle>
      <FlexJustify>
        <div />
        <ButtonIcon color="#fff">
          <FaFilter size={20} style={{ marginRight: 10 }} />
          Filter
        </ButtonIcon>
      </FlexJustify>
      <Tables headerKeys={tableKeys}>
        {summoners.map((summ, i) => (
          <TableRow key={i}>
            <td>{i + 1}</td>
            <TdFlex>
              <SummonerAvatar
                src={`https://ddragon.leagueoflegends.com/cdn/10.10.3216176/img/profileicon/${summ.profileIconId}.png`}
              />
              {summ.name}
            </TdFlex>
            <td>{moment(summ.createdAt).format('MM/DD/YYYY')}</td>
            <td>{summ.email}</td>
            <td>
              <FlexJustify justify="center">
                <IconContainer
                  onClick={() => {
                    setOpenBlockModal(true);
                    setSelectedSummoner(summ._id);
                  }}
                >
                  <BsFillLockFill color="#fff" size={20} />
                </IconContainer>
                <IconContainer
                  onClick={() => {
                    setOpenDeleteModal(true);
                    setSelectedSummoner(summ._id);
                  }}
                >
                  <IoMdTrash color="#fff" size={20} />
                </IconContainer>
              </FlexJustify>
            </td>
          </TableRow>
        ))}
      </Tables>
    </PageWrapper>
  );
};

export default Users;
