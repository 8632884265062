import { useState, useEffect } from 'react';
import axios from 'axios';

const useGetChamps = () => {
  const [champs, setChamps] = useState([]);

  useEffect(() => {
    const getLoLChamps = async () => {
      const version = await axios.get('https://ddragon.leagueoflegends.com/api/versions.json');
      const { data } = await axios.get(`https://ddragon.leagueoflegends.com/cdn/${version.data[0]}/data/en_US/champion.json`);
      setChamps(data.data);
    }

    getLoLChamps();
  }, []);

  return {
    champs
  }
};

export default useGetChamps;