import { useEffect, useState } from 'react';
import { getChallengeDetails } from '../services/getChallengeDetails';
import { getChallengeLeaderboard } from '../services/getChallengeLeaderBoard';

const useGetChallenge = (idChallenge) => {
  const [challenge, setChallenge] = useState({});
  const [challengeLeaderBoard, setChallengeLeaderBoard] = useState([]);

  useEffect(() => {
    if (idChallenge) {
      (async () => {
        try {
          const challengesObj = await getChallengeDetails(idChallenge);
          const challengeLB = await getChallengeLeaderboard(idChallenge);
          setChallenge(challengesObj);
          setChallengeLeaderBoard(challengeLB);
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [idChallenge]);

  return { challenge, challengeLeaderBoard };
};

export default useGetChallenge;
