import React from 'react';
import { ErrorMsg } from '../Input/Input.style';
import { SelectField, SelectContainer, SelectText } from './Select.style';

const Select = ({ text, value, onChange, children, error, style, width, disabled }) => (
  <SelectContainer style={style} width={width}>
    {text && <SelectText>{text}</SelectText>}
    <SelectField value={value} onChange={onChange} disabled={disabled}>
      {children}
    </SelectField>
    <ErrorMsg>{error}</ErrorMsg>
  </SelectContainer>
);

export default Select;