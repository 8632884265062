import styled from 'styled-components';

export const DatePickerWrapper = styled.div`
  
  .DateInput_input {
    width: 100%;
    padding: 1rem;
    outline: 0;
    border: 0;
    border-radius: 5px;
    font-size: 1.6rem;
    ::placeholder {
      color: #435A6F78;
    }
  }
  .DateInput {
    border-radius: 5px;
  }
  .SingleDatePickerInput__withBorder {
    border-radius: 5px;

    border: 0;
    :focus {

      height: 294px;
    }
  }
  .DateInput_input__focused {
    display: none;
  }

  .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    background: #151A30;
    border: 1px double #151A30;
    color: #fff;
  }

  .DateInput_fang {
    display: none;
  }

  .SingleDatePicker_picker {
    top: -10px !important;
    > div {
      box-shadow: unset;
    }
  }

  .DayPickerKeyboardShortcuts_show__bottomRight {
    display: none;
  }
`;

