import React from 'react';
import { SearchIcon, InputSearch, SearchInputContainer } from './SearchInput.style';

const SearchInput = () => (
  <SearchInputContainer>
    <SearchIcon size={20} color="#777B89" />
    <InputSearch placeholder="search..." />
  </SearchInputContainer>
);

export default SearchInput;