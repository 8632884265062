import styled from 'styled-components';
import { IoIosSearch } from 'react-icons/io';
export const InputSearch = styled.input`
  width: 100%;
  background: #292F45;
  padding: 1rem 1rem 1rem 3.5rem;
  color: #777B89;
  font-weight: bold;
  outline: 0;
  border: 0;
  border-radius: 5px;
  ::placeholder {
    color: #777B89;
    font-weight: bold;
  }
`;

export const SearchInputContainer = styled.div`
  width: 250px;
  position: relative;
  display:flex;
  align-items: center;
`;

export const SearchIcon = styled(IoIosSearch)`
  position: absolute;
  left: .8rem;
`;