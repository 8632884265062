import React, { useState } from 'react';
import { LoginWrapper } from './Login.style';
import { AuthForm, AuthFormTitle, ButtonDashboard } from '../../styles/Shared';
import Input from '../../components/Input/Input';
import useInput from '../../hooks/useInput';
import ReactLoading from 'react-loading';
import { signin } from '../../helpers/auth';

const Login = () => {
  const email = useInput();
  const password = useInput();
  const [loading, setLoading] = useState(false);

  const handleSignin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await signin(email.input, password.input);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <LoginWrapper>
      <AuthForm onSubmit={handleSignin}>
        <AuthFormTitle>Sign in</AuthFormTitle>
        <Input
          text="Email"
          type="email"
          marginRight={0}
          value={email.input}
          onChange={email.onChange}
        />
        <Input
          type="password"
          text="Password"
          marginRight={0}
          style={{ marginTop: 40 }}
          value={password.input}
          onChange={password.onChange}
        />
        <ButtonDashboard
          type="submit"
          width="100%"
          disabled={loading}
          style={{ marginTop: 60 }}
        >
          {loading ? (
            <ReactLoading type="spin" color="#fff" height={25} width={25} />
          ) : (
            'Sign in'
          )}
        </ButtonDashboard>
      </AuthForm>
    </LoginWrapper>
  );
};

export default Login;
