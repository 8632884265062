import styled from 'styled-components';
import { Link } from '@reach/router';

export const LinkButton = styled(Link)`
  padding: 1.5rem 3rem;
  text-transform: uppercase;
  font-size: 1.3rem;
  letter-spacing: 1px;
  font-family: inherit;
  border-radius: 5px;
  font-weight: bold;
  background: #3C25FA;
  color: #fff;
`;