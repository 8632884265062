import React from 'react';
import { Table, HeaderTr } from './Tables.style';

const Tables = ({ headerKeys = [], children }) => (
  <Table>
    <thead>
      <HeaderTr>
        {headerKeys.map((key, i) => (
          <th key={i}>{key}</th>
        ))}
      </HeaderTr>
    </thead>
    <tbody>
      {children}
    </tbody>
  </Table>
);

export default Tables;
