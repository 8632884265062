import React from 'react';
import { IoIosNotifications } from "react-icons/io";
import { MdArrowDropDown } from "react-icons/md";
import { TopBarWrapper, UserArea, UserImg, UserName } from './TopBar.style';
import SearchInput from '../SearchInput/SearchInput';
import useCurrentUser from '../../hooks/useCurrentUser';
import buildImgName from '../../helpers/buildImgName';
const TopBar = () => {
  const { name } = useCurrentUser();
  return (
    <TopBarWrapper>
      <SearchInput />
      <UserArea>
        <IoIosNotifications size={25} color="#fff" />
        <UserImg>{buildImgName(name)}</UserImg>
        <UserName>{name}</UserName>
        <MdArrowDropDown size={15} color="#fff" />
      </UserArea>
    </TopBarWrapper>
  )
};

export default TopBar;