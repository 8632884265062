import React, { useState } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { SingleDatePicker } from 'react-dates';
import { DatePickerWrapper } from './DatePicker.style';
import { Text } from '../TimePicker/TimePicker.style';

const DatePicker = ({ date, setDate, id, text }) => {
  const [focused, setFocused] = useState(false);
  return (
    <DatePickerWrapper>
      <Text>{text}</Text>
      <SingleDatePicker
        date={date} onDateChange={date => setDate(date)}
        focused={focused}
        onFocusChange={({ focused }) => setFocused(focused)} id={id}
        showDefaultInputIcon
        placeholder={text} />

    </DatePickerWrapper>

  )
};

export default DatePicker;