import React from 'react';
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import { TimePickerWrapper, Text } from './TimePicker.style';

const TPicker = ({ text, onChange, value, }) => (
  <TimePickerWrapper>
    <Text>{text}</Text>
    <TimePicker
      showSecond={false}
      value={value}
      placeholder={text}
      onChange={onChange}
      inputReadOnly
    />
  </TimePickerWrapper>

)

export default TPicker;