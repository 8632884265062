const buildImgName = (name) => {
  if (!name) {
    return 'CA';
  }
  const splited = name.split(' ');
  if (splited.length === 2) {
    return `${splited[0][0]}${splited[1][0]}`
  }

  return splited[0][0];
}

export default buildImgName;