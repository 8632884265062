import styled from 'styled-components';

export const SearchChampContainer = styled.div`
  position:relative;
  width: 250px;
  height: 60px;
  input:focus + div{
    display: block;
  }
`;

export const SearchChampItems = styled.div`
  position: absolute;
  top: 40px;
  border-radius: 5px;
  display: none;
  width: 100%;
  height: 200px;
  overflow-y: auto;
  background :#fff;
  :hover {
    display: block;
  }
`;

export const SearchedItem = styled.div`
  width: 100%;
  padding: .5rem;
  display:flex;
  align-items: center;
  font-size: 1.3rem;
  color: #222;
  transition: all .3s ease;
  cursor: pointer;
  border-radius: 5px;
  :hover {
    background: #f2f2f2;;
  }
`;

export const ImgChamp = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 1rem;
  object-fit:cover;
  object-position: center;
`;