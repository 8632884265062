import { api } from './axios';

export const createChallengeService = async (data) => {
  try {
    const res = await api().post('/challenges', data);
    return res;
  } catch (error) {
    throw error;
  }
};
