import styled from 'styled-components';

export const InputWrapper = styled.div`
  width: ${props => props.width + 'px' || '300px'};
  margin-right: ${props => props.marginRight + 'px' || '3rem'};
  height: 36px;
  margin-bottom: 1rem;
`;

export const InputLabel = styled.label`
  font-size: 1.4rem;
  color: #fff;
`;

export const InputField = styled.input`
  width: 100%;
  padding: 1rem;
  outline: 0;
  margin-top: 1rem;
  border: 0;
  border-radius: 5px;
  font-weight: bold;
  ::placeholder {
    color: #435A6F78;
  }
`;

export const ErrorMsg = styled.div`
  width: 100%;
  padding: .5rem 0;
  color: red;
`;