import { useState } from 'react';

const useInput = (initialState = '') => {
  const [input, setInput] = useState(initialState);
  const [error, setError] = useState('');
  const onChange = e => {
    const { value } = e.target;
    if (value) {
      setError('');
    }
    setInput(value);
  }

  const onClearInput = () => setInput('')

  return {
    input,
    onChange,
    error,
    setError,
    onClearInput

  }
}

export default useInput;