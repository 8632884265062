import styled from 'styled-components';

export const PageTitle = styled.h2`
  font-size: 1.8rem;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  margin: 1.5rem 0;
`;

export const PageWrapper = styled.div`
  width: 100%;
  max-width: 90%;
  margin: auto;
  padding-top: 1rem;
  padding-bottom: 3rem;
  height: 100%;
  background: #192038;
`;

export const AuthForm = styled.form`
  width: 100%;
  max-width: 500px;
  border-radius: 5px;
  box-shadow: 0 4px 14px rgba(0,0,0,.2);
  padding: 1rem 3rem 3rem;
  background:#0f1425;
`;

export const AuthFormTitle = styled.h1`
  color: #fff;
  text-align: center;
  padding: 2rem 0;

`;

export const Text = styled.p`
  font-size: 1.4rem;
  color: #fff;
`;

export const ButtonIcon = styled.div`
  width: 100%; 
  padding: 1rem;
  font-weight: bold;
  letter-spacing: 1px;
  margin: 1rem 0;
  display:flex;
  align-items: center;
  cursor: pointer;
  font-size: 1.3rem;
  transition: ease all .3s;
  text-transform: uppercase;
  color: ${props => props.color || '#2E3A59'};
  :hover{
    color: #fff;
  }
`;

export const SummonerAvatar = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  object-position: center;
`;

export const TdFlex = styled.td`
  display:flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ButtonDashboard = styled.button`
  padding: 1.5rem 3rem;
  text-transform: uppercase;
  font-size: 1.3rem;
  letter-spacing: 1px;
  border-radius: 5px;
  font-weight: bold;
  background: #3C25FA;
  display:flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: ${props => props.width};
`;

export const IconContainer = styled.div`
  width: 25px;
  height: 25px;
  display:flex;
  cursor: pointer;
  margin-right: 1rem;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: #222B45;
`;

export const PageSection = styled.h2`
  font-size: 1.6rem;
  text-transform: uppercase;
  color: #fff;
  border-bottom: 0.5px solid rgba(228, 233, 242, .5);
  font-weight: bold;
  margin: 1.5rem 0;
  padding: 1rem 0;
`;


export const FlexJustify = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${ props => props.justify || 'space-between'};
`;