import { api } from './axios';

export const getChallengeDetails = async (id) => {
  try {
    const { data } = await api().get(`/challenges/${id}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};
