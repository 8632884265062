import { api } from './axios';

export const getSummoners = async (challengeId) => {
  try {
    const { data } = await api().get(`/summoners`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteSummoners = async (summonerId) => {
  try {
    const { data } = await api().delete(`/summoners/${summonerId}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const blockUser = async (summonerId) => {
  try {
    const { data } = await api().post(`/summoners/${summonerId}/block`);
    return data;
  } catch (error) {
    console.log(error);
  }
};
