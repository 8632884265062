import styled from 'styled-components';

export const LoginWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display:flex;
  align-items: center;
  justify-content: center;
  background:#192038;
`;

