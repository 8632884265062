import React from 'react';
import StyledCheckbox from './Checkbox.styled';

const Checkbox = ({
  name,
  value,
  change,
  labelText,
  id,
  disabled,
}) => (
    <StyledCheckbox disabled={disabled}>
      <input
        type="checkbox"
        name={name}
        id={id}
        checked={value}
        onChange={change}
        disabled={disabled}
      />
      <label htmlFor={id}>
        {labelText}
      </label>
    </StyledCheckbox>
  );

export default Checkbox;
