import { useEffect, useState } from 'react';
import { getSummoners } from '../services/sumonners';

const useSummoners = () => {
  const [summoners, setSummoners] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [indexPerPage, setIndexPerPage] = useState(0);
  const [pages, setPages] = useState(0);
  const countPerPage = 10;
  const rangeDisplay = 5;

  const onGetSummoners = async () => {
    try {
      const summonersArr = await getSummoners();
      setSummoners(summonersArr);
      setPages(Math.ceil(summonersArr.length / countPerPage));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    onGetSummoners();
    return () => onGetSummoners;
  }, []);

  const onNextPage = () => {
    setIndexPerPage(indexPerPage + 10);
    setActivePage(activePage + 1);
    setSummoners(summoners.slice(indexPerPage, activePage * countPerPage + 1));
  };

  const onPrevPage = () => {
    if (activePage === 1) {
      return;
    }
    setIndexPerPage(indexPerPage - 10);
    setActivePage(activePage - 1);
  };

  return {
    summoners,
    activePage,
    countPerPage,
    rangeDisplay,
    pages,
    onNextPage,
    onPrevPage,
    refreshSummoners: onGetSummoners,
  };
};

export default useSummoners;
