import React, { useState, useEffect } from 'react';
import { Router, navigate } from '@reach/router';
import Dashboard from './pages/Dashboard/Dashboard';
import ChallengeDetails from './pages/ChallengeDetails/ChallengeDetails';
import Users from './pages/Users/Users';
import NewChallenge from './pages/NewChallenge/NewChallenge';
import Layout from './components/Layout/Layout';
import { auth } from './services/firebase';
import Login from './pages/Login/Login';
import EditChallenge from './pages/EditChallenge/EditChallenge';

const App = () => {
  const [isAuth, setIsAuth] = useState(false);
  useEffect(() => {
    auth().onAuthStateChanged((user) => {
      if (user) {
        // setUsers(user);

        user.getIdToken().then((token) => {
          // activateAuth({ ...user, tokenId: token });
          sessionStorage.setItem('pokyDashboardAuthToken', token);
          sessionStorage.setItem(
            'pokyDashboardRefreshToken',
            user.refreshToken
          );
        });
        setIsAuth(true);
        navigate('/');

        // if (loadingOrg === 'resolved') {
        //   setLoading(false);
        // }
      } else {
        setIsAuth(false);
        navigate('/login');
      }
    });
  }, []);

  if (isAuth) {
    return (
      <Layout>
        <Router>
          <Dashboard path="/" />
          <Users path="/users" />
          <NewChallenge path="/new-challenge" />
          <ChallengeDetails path="/challenge/:id" />
          <EditChallenge path="/edit-challenge/:id" />
        </Router>
      </Layout>
    );
  }

  return (
    !isAuth && (
      <Router>
        <Login path="/login" />
      </Router>
    )
  );
};

export default App;
