import styled from 'styled-components';

export const ChallengeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5rem;
  margin-top: 3rem;
`;

export const ChallengeInfo = styled.div`
  width: 500px;
  margin-right: 1rem
`;

export const VideoContainers = styled.div`
  display:flex;
`;

export const Video = styled.iframe`
  width: 100%;
  max-width: 200px;
  border: 0;
  border-radius: 5px;
  height: 120px;
  margin-right: 1rem;
`;

export const ChallengeDetail = styled.div`
  width: 100%;
  max-width: 600px;

`;

export const ChallengeImgContainer = styled.div`
  width: 100%;
  height: 400px;
  position: relative;
`;

export const ChallengeCondition = styled.div`
  margin: 1.5rem 0;
  display:flex;
  font-size: 1.4rem;
  padding: 1rem 0;
  border-bottom: 1px solid #ddd;
  color: #ddd;
  justify-content: space-between;
`;

export const ChallengeImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

export const ChallengeRewardBox = styled.div`
  background: #0F1425;
  padding: 1rem 3rem;
  width: 100%;
  position: absolute;
  color: #fff;
  display:flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
  bottom: 0;
  left: 0;
`;

export const ChallengeDescripition = styled.p`
  width: 100%;
  margin: 3rem 0 2rem;
  font-size: 1.5rem;
  color: #fff;
`;

export const ChallengeLabel = styled.h3`
  font-size: 1.6rem;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 2rem;
`;

export const ChampsContainer = styled.div`
  display:flex;
  flex-wrap:wrap;
  margin: 1rem 0;
`;

export const Champ = styled.div`
  width: 50px;
  height: 60px;
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 1rem;
`;

export const ChampImg = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  margin-bottom: .5rem;
`;

export const ChampName = styled.div`
  font-size: 1.3rem;
  color: #fff;
  text-align: center;
`;