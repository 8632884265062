import styled from 'styled-components';

export const LayoutContainer = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-template-areas: 'sidemenu content';
`;

export const LayoutContent = styled.div`
  background: #192038;
  grid-area: content;
  min-height: 100vh;
`;