import React from 'react';
import { FlexJustify, ButtonDashboard } from '../../styles/Shared';
import useInput from '../../hooks/useInput';
import { InputField, InputLabel } from '../Input/Input.style';

const RenderFields = ({ onAdd, placeholder, text, withExtraInput, items, setItem }) => {
  const { input, onChange, onClearInput } = useInput();
  const type = useInput();

  const onClickHandler = () => {
    onAdd(input, type.input, setItem, items);
    onClearInput();
    type.onClearInput();
  }

  return (
    <div>
      <InputLabel>{text}</InputLabel>
      <FlexJustify justify="flex-start" style={{ marginTop: '1rem' }}>
        {withExtraInput && (
          <InputField style={{ marginTop: '0' }} type="text" placeholder="type" value={type.input} onChange={type.onChange} />
        )}
        <InputField style={{ marginTop: '0' }} type="text" placeholder={placeholder} value={input} onChange={onChange} />
        <ButtonDashboard type="button" style={{ padding: '1rem 3rem' }} onClick={onClickHandler}>Add</ButtonDashboard>
      </FlexJustify>
    </div>
  )
};

export default RenderFields;