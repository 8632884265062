import styled from 'styled-components';

const StyledCheckbox = styled.div`
  [type='checkbox']:checked,
  [type='checkbox']:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type='checkbox']:checked + label,
  [type='checkbox']:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    font-size: 1.6rem;
    line-height: 20px;
    /* display: inline-block; */
    color: #fff;
    font-weight: 500;
  }
  [type='checkbox']:checked + label:before,
  [type='checkbox']:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: ${props => (props.disabled ? '#ddd' : 'rgb(242, 242, 247)')};
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  [type='checkbox']:checked + label:after,
  [type='checkbox']:not(:checked) + label:after {
    content: '';
    width: 8px;
    height: 8px;
    background: #3C25FA;
    position: absolute;
    top: 5px;
    border-radius: 2px;
    left: 5.1px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type='checkbox']:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type='checkbox']:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`;

export default StyledCheckbox;
