import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { BsFillLockFill } from 'react-icons/bs';
import { IoMdTrash } from 'react-icons/io';
import { GiPointySword } from 'react-icons/gi';
import useGetChallenge from '../../hooks/useGetChallenge';
import {
  PageWrapper,
  PageTitle,
  FlexJustify,
  Text,
  SummonerAvatar,
  TdFlex,
  IconContainer,
} from '../../styles/Shared';
import {
  ChallengeContainer,
  ChallengeImgContainer,
  ChallengeImg,
  ChallengeRewardBox,
  ChallengeDescripition,
  ChallengeLabel,
  ChallengeInfo,
  VideoContainers,
  Video,
  ChallengeDetail,
  ChallengeCondition,
  ChampsContainer,
  Champ,
  ChampImg,
  ChampName,
} from './ChallengeDetails.style';
import Tables from '../../components/Tables/Tables';
import TableRow from '../../components/Tables/TableRow/TableRow';
import { LinkButton } from '../Dashboard/Dashboard.style';
import useGetChamps from '../../hooks/useGetChamps';

const tableKeys = ['Rank', 'Summoner Name', 'Server', 'Score', 'Actios'];

const storeOnSession = data => {
  sessionStorage.setItem('challenge', JSON.stringify(data));
};

const ChallengeDetails = ({ id }) => {
  const { challenge, challengeLeaderBoard } = useGetChallenge(id);
  const { champs } = useGetChamps();
  const [selectedChamps, setSelectedChamps] = useState([]);

  useEffect(() => {
    const setChampions = () => {
      const arrChamps = [];
      Object.keys(champs).filter(champ => {
        for (const ch of challenge?.requirements?.conditions?.[1]?.value) {
          if (champs[champ].key === ch.toString()) {
            arrChamps.push({
              ...champs[champ],
              champIcon: `https://ddragon.leagueoflegends.com/cdn/10.13.1/img/champion/${
                champs[champ].id
                }.png`,
            });
          }
        }
      });

      console.log(arrChamps);
      setSelectedChamps(arrChamps);
    };
    console.log(challenge);
    if (challenge?.requirements?.conditions?.[1]?.value) {
      setChampions();
    }
  }, [challenge, champs]);
  return (
    <PageWrapper>
      <PageTitle>Challenge</PageTitle>
      <FlexJustify>
        <ChallengeLabel style={{ margin: 0 }}>{challenge.title}</ChallengeLabel>
        <LinkButton
          to={`/edit-challenge/${id}`}
          onClick={() => storeOnSession(challenge)}
        >
          Edit Challenge
        </LinkButton>
      </FlexJustify>
      <ChallengeContainer>
        <ChallengeInfo>
          <ChallengeImgContainer>
            <ChallengeImg src={challenge.photoURL} alt="not img" />
            <ChallengeRewardBox />
          </ChallengeImgContainer>
          <ChallengeDescripition>{challenge.description}</ChallengeDescripition>

          <FlexJustify>
            <div>
              <ChallengeLabel>Start</ChallengeLabel>
              <Text>{moment(challenge.startDate).format('L, h:mm a')}</Text>
            </div>
            <div>
              <ChallengeLabel>End</ChallengeLabel>
              <Text>{moment(challenge.endDate).format('L, h:mm a')}</Text>
            </div>
          </FlexJustify>
        </ChallengeInfo>
        <ChallengeDetail>
          <VideoContainers>
            {challenge?.gallery?.map((video, i) => (
              <Video
                key={i}
                src={video || 'https://www.youtube.com/embed/mLqksQzJo6U'}
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            ))}
          </VideoContainers>
          <br />
          <ChallengeInfo>
            <ChallengeLabel>Requerements</ChallengeLabel>
            <ChallengeCondition>
              <span>Force match to win</span>{' '}
              <span>
                {challenge?.requirements?.conditions?.[0].value === true
                  ? 'Yes'
                  : 'No'}
              </span>
            </ChallengeCondition>
          </ChallengeInfo>
          <ChallengeInfo>
            <ChallengeLabel
              style={{ textTransform: 'unset', fontSize: '1.3rem' }}
            >
              Champions Allowed
            </ChallengeLabel>
            {!challenge?.requirements?.conditions?.[1]?.operator && (
              <ChampsContainer>
                {selectedChamps?.map((champ, i) => (
                  <Champ key={i}>
                    <ChampImg src={champ.champIcon} />
                    <ChampName>{champ.name}</ChampName>
                  </Champ>
                ))}
              </ChampsContainer>
            )}
          </ChallengeInfo>
          <ChallengeInfo>
            <ChallengeLabel
              style={{ textTransform: 'unset', fontSize: '1.3rem' }}
            >
              Champions Blocked
            </ChallengeLabel>
            {challenge?.requirements?.conditions?.[1]?.operator && (
              <ChampsContainer>
                {selectedChamps?.map((champ, i) => (
                  <Champ key={i}>
                    <ChampImg src={champ.champIcon} />
                    <ChampName>{champ.name}</ChampName>
                  </Champ>
                ))}
              </ChampsContainer>
            )}
          </ChallengeInfo>
        </ChallengeDetail>
      </ChallengeContainer>
      <ChallengeLabel>Leaderboard</ChallengeLabel>
      <Tables headerKeys={tableKeys}>
        {challengeLeaderBoard?.leaderboard?.map((summ, i) => (
          <TableRow key={i}>
            <td>{i + 1}</td>
            <TdFlex>
              <SummonerAvatar
                src={`https://ddragon.leagueoflegends.com/cdn/10.10.3216176/img/profileicon/${
                  summ.profileIconId
                  }.png`}
              />
              {summ.name}
            </TdFlex>
            <td>{summ.region}</td>
            <td>
              <FlexJustify justify="center">
                {summ?.score || 0}{' '}
                <GiPointySword
                  style={{ marginLeft: '10' }}
                  color="#9C11A3"
                  size={25}
                />
              </FlexJustify>
            </td>

            <td>
              <FlexJustify justify="center">
                <IconContainer>
                  <BsFillLockFill color="#fff" size={20} />
                </IconContainer>
                <IconContainer>
                  <IoMdTrash color="#fff" size={20} />
                </IconContainer>
              </FlexJustify>
            </td>
          </TableRow>
        ))}
      </Tables>
    </PageWrapper>
  );
};

export default ChallengeDetails;
