import { api } from './axios';

export const getChallengesService = async () => {
  try {
    const { data } = await api().get('/challenges');
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateChallenge = async (id, requestData) => {
  try {
    const { data } = await api().put(`/challenges/${id}`, requestData);
    return data;
  } catch (error) {
    throw error;
  }
};
