import React from 'react';
import { RowWrapper } from './TableRow.style';

const TableRow = ({ children }) => {
  return (
    <RowWrapper>
      {children}
    </RowWrapper>
  )
};

export default TableRow;
