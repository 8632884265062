import styled from 'styled-components';

export const SectionWrapper = styled.div`
  display:flex;
  flex-wrap: wrap;
`;

export const ColumnWrapper = styled.div`
  width: 300px;
`;

export const ItemsContainer = styled.div`
  width: 300px;
  height: fit-content;
  max-height: 300px;
  margin-bottom: 1rem;
  margin-top: 1rem;
  overflow-y: auto;
`;

export const ItemList = styled.div`
  margin-bottom: 1rem;
  display:flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  padding: 1rem;
  background: #0F1425;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 1px;
  color: #fff;
`;

export const FlexItems = styled.div`
  display:flex;
  justify-content: space-between;
  ${props => props.wrap && 'flex-wrap: wrap'};
`;

export const FlexColumn = styled.div`
  display:flex;
  flex-direction: column;
`;

export const AlignedItems = styled.div`
  display:flex;
  align-items: center;
`;