import { useState } from 'react';
import firebase from 'firebase';

const useCurrentUser = () => {
  const [user] = useState(firebase.auth().currentUser);

  return {
    id: user.uid,
    name: user.displayName,
    email: user.email,
    img: user.photoURL,
  };
};

export default useCurrentUser;
