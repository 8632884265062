import React from 'react';
import { InputWrapper, InputLabel, InputField, ErrorMsg } from './Input.style';

const Input = ({ text, value, onChange, placeholder, error, type, width, marginRight = 30, style }) => (
  <InputWrapper width={width} style={style} marginRight={marginRight}>
    <InputLabel>{text}</InputLabel>
    <InputField type={type} onChange={onChange} value={value} placeholder={placeholder} />
    <ErrorMsg>
      {error}
    </ErrorMsg>
  </InputWrapper>
);

export default Input;