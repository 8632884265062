import styled from 'styled-components';
import { Link } from '@reach/router';
import logo from '../../assets/pokylogo.png';


export const Menu = styled.div`
  width: 300px;
  padding: 1rem;
  background: #0F1425;
  grid-area: sidemenu;
  display:flex;
  flex-direction: column;
  position: fixed;
  height: 100vh;
`;

export const Logo = styled.div`
  height: 200px;
  width: 150px;
  margin: auto;
  background: url(${logo});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  display:flex;
  align-items: center;
  justify-content: center;
  font-size: 4.2rem;
  color: #fff;
  font-weight: bold;
`;

export const NavMenu = styled.nav`
  flex-grow: 1;
  padding: 0 2rem;
  display:flex;
  flex-direction: column;
`;

export const MenuItem = styled(Link)`
  width: 100%; 
  padding: 1rem;
  font-weight: bold;
  margin: 1rem 0;
  display:flex;
  letter-spacing: 1px;
  align-items: center;
  cursor: pointer;
  color: #2E3A59;
  font-size: 1.3rem;
  transition: ease all .3s;
  text-transform: uppercase;
  &[aria-current] {
    color: #fff;
  }
  :hover {
    color: #fff;
  }
`;

export const BottomButtons = styled.div`
  padding: 0 2rem;
`;
