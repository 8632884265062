import styled from 'styled-components';

export const TopBarWrapper = styled.div`
  width: 100%;
  height: 60px;
  background: #151A30;
  padding: 1rem 3rem;
  display:flex;
  align-items: center;
  justify-content: space-between;
`;

export const UserArea = styled.div`
  display:flex;
  align-items: center;
  justify-content: space-evenly;

`;

export const UserImg = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display:flex;
  margin: 0 1rem;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #D9822B;
  text-transform: uppercase;
  font-size: 2.2rem;
`;

export const UserName = styled.span`
  font-size: 1.3rem;
  font-weight: 100;
  margin-right: 1rem;
  text-transform: capitalize;
  color: #fff;
`;