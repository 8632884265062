import styled from 'styled-components';

export const SelectField = styled.select`
  outline: 0;
  border: 0;
  border-radius: 5px;
  width: 100%;
  padding: 1rem;
`;

export const SelectText = styled.div`
  font-size: 1.4rem;
  color: #fff;
  margin-bottom: 1rem;
`;

export const SelectContainer = styled.div`
  width: ${props => props.width + 'px' || '300px'};
  margin-right: ${props => props.marginRight + 'px' || '3rem'};
`;