import styled from 'styled-components';

export const TimePickerWrapper = styled.div`
   input {
    padding: 1rem;
    height: 44px;
    font-size: 1.3rem;
    color: #222;
  }
  .rc-time-picker-clear {
    display: none;
  }
`;

export const Text = styled.div`
  font-size: 1.3rem;
  color: #fff;
  margin-bottom: 1rem;
`;