import React from 'react';
import { Menu, Logo, NavMenu, MenuItem, BottomButtons } from './SideMenu.style';
import { MdDashboard, MdSettings } from 'react-icons/md';
import { FaUserFriends } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { ButtonIcon } from '../../styles/Shared';
import { logout } from '../../helpers/auth';
const size = 25;
const marginRight = { marginRight: 10 };

const SideMenu = () => (
  <Menu>
    <Logo />
    <NavMenu>
      <MenuItem to="/"><MdDashboard size={size} style={marginRight} /> dashboard</MenuItem>
      <MenuItem to="/users"> <FaUserFriends size={size} style={marginRight} /> users</MenuItem>
    </NavMenu>
    <BottomButtons>
      <MenuItem to="/settings" >
        <MdSettings size={size} style={marginRight} />
        settings
      </MenuItem>
      <ButtonIcon onClick={logout}>
        <FiLogOut size={size} style={marginRight} />
        logout
      </ButtonIcon>
    </BottomButtons>
  </Menu>
);

export default SideMenu;
