import React from 'react';
import { Link } from '@reach/router';
import get from 'lodash/get';
import {
  PageWrapper,
  PageTitle,
  FlexJustify,
  ButtonIcon,
} from '../../styles/Shared';
import { LinkButton } from './Dashboard.style';
import { FaFilter } from 'react-icons/fa';
import Tables from '../../components/Tables/Tables';
import TableRow from '../../components/Tables/TableRow/TableRow';
import useGetChallenges from '../../hooks/useGetChallenges';
import moment from 'moment';
import { getChallengesService } from '../../services/chanllengeService';

const tableKeys = [
  '#',
  'challenge name',
  'created by',
  'start date',
  'end date',
  'users',
  'status',
];

const Dashboard = () => {
  // const { challenges } = useGetChallenges();
  const [challenges, setChallenges] = React.useState([]);
  React.useEffect(() => {
    getChallengesService()
      .then((data) => setChallenges(data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <PageWrapper>
      <PageTitle>Dashboard</PageTitle>
      <FlexJustify>
        <PageTitle>Challenge List</PageTitle>
        <LinkButton to="/new-challenge">create challenge</LinkButton>
      </FlexJustify>
      <ButtonIcon color="#fff">
        <FaFilter size={20} style={{ marginRight: 10 }} />
        Filter
      </ButtonIcon>
      <Tables headerKeys={tableKeys}>
        {challenges.map((challenge, i) => (
          <TableRow key={i}>
            <td>{i + 1}</td>
            <td>
              <Link
                to={`/challenge/${challenge._id}`}
                style={{ color: '#fff' }}
              >
                {challenge.title}
              </Link>
            </td>
            <td>{get(challenge, 'createdBy.email', '')}</td>
            <td>{moment(challenge.startDate).format('L')}</td>
            <td>{moment(challenge.endDate).format('L')}</td>
            <td>
              {challenge?.participants
                ? Object.keys(challenge.participants).length
                : 0}
            </td>
            <td>{challenge.status}</td>
          </TableRow>
        ))}
      </Tables>
    </PageWrapper>
  );
};

export default Dashboard;
