import React, { useState } from 'react';
import { Textarea } from 'evergreen-ui';
import ReactLoading from 'react-loading';
import { IoMdTrash } from 'react-icons/io';
import moment from 'moment';
import {
  FlexJustify,
  PageTitle,
  PageSection,
  PageWrapper,
  ButtonDashboard,
  IconContainer,
} from '../../styles/Shared';
import {
  SectionWrapper,
  FlexColumn,
  ColumnWrapper,
  ItemsContainer,
  ItemList,
  AlignedItems,
  FlexItems,
} from './NewChallenge.style';
import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';
import useInput from '../../hooks/useInput';
import useCurrentUser from '../../hooks/useCurrentUser';
import { createChallenge } from '../../services/challenges';
import DatePicker from '../../components/DatePicker/DatePicker';
import RenderFields from '../../components/RenderFields/RenderFields';
import SearchChamp from '../../components/SearchChamp/SearchChamp';
import { ChallengeLabel } from '../ChallengeDetails/ChallengeDetails.style';
import Checkbox from '../../components/Checkbox/Checkbox';
import { ImgChamp } from '../../components/SearchChamp/SearchChamp.style';
import { InputField } from '../../components/Input/Input.style';
import 'rc-time-picker/assets/index.css';
import TPicker from '../../components/TimePicker/TimePicker';
import { navigate } from '@reach/router';
import { toast } from 'react-toastify';
import { createChallengeService } from '../../services/createChallenge';

const NewChallenge = () => {
  const { email } = useCurrentUser();
  const [loading, setLoading] = useState(false);
  const challengeName = useInput();
  const challengeNameEs = useInput();
  const challengeImg = useInput();
  const description = useInput();
  const excerpt = useInput();
  const excerptEs = useInput();
  const descriptionEs = useInput();
  const [challengeType, setChallengeType] = useState('');
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [prizes, setPrizes] = useState([]);
  const [rules, setRules] = useState([]);
  const [rulesEs, setRulesEs] = useState([]);
  const [matchWin, setMatchWin] = useState(true);
  const [soloQAndFLex, setSoloQAndFLex] = useState(true);
  const [selectedChamps, setSelectedChamps] = useState([]);
  const [champsOption, setChampsOption] = useState('allowed');
  const [results, setResults] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [startTime, setStartTime] = useState(moment());
  const [endTime, setEndTime] = useState(moment());
  const coins = useInput('');

  const onAddItem = (item, _, setItem, items) => {
    if (!item) {
      return;
    }
    setItem([...items, item]);
  };

  const onSelectChamp = (champ) =>
    setSelectedChamps([...selectedChamps, champ]);

  const onDeleteChamp = (champ, setChamp, champs) => {
    const champsCop = [...champs];
    champsCop.splice(champ, 1);
    setChamp(champsCop);
  };

  const onDeleteItem = (item, setItem, items) => {
    const itemsCop = [...items];
    itemsCop.splice(item, 1);
    setItem(itemsCop);
  };

  const onAddPrize = (item, type) => {
    if (!item) {
      return;
    }
    setPrizes([...prizes, { type, value: item }]);
  };

  const onDeletePrizeItem = (item) => {
    const prizesCop = [...prizes];
    prizesCop.splice(item, 1);
    setPrizes(prizesCop);
  };

  const onDeleteRuleItem = (item) => {
    const rulesCop = [...rules];
    rulesCop.splice(item, 1);
    setRules(rulesCop);
  };

  const setInputErrors = () => {
    if (!challengeImg.input) {
      challengeImg.setError('Please fill in the input');
    }

    if (!challengeName.input) {
      challengeName.setError('Please fill in the input');
    }
  };

  const setConditions = () => {
    const conditions = [];
    if (matchWin) {
      conditions.push({
        key: 'stats.win',
        value: matchWin,
      });
    }
    if (selectedChamps.length > 0) {
      const champ = {
        key: 'championId',
        value: selectedChamps.map((champ) => parseInt(champ.key)),
      };

      if (champsOption === 'blocked') {
        conditions.push({ ...champ, operator: 'not' });
      } else {
        conditions.push(champ);
      }
    }
    return conditions;
  };

  const onChangePrizeItems = (e, index, prop) => {
    const { value } = e.target;

    setPrizes(
      prizes.map((item, i) => {
        if (i === index) {
          item[prop] = value;
          return item;
        }
        return item;
      })
    );
  };

  const onAddResult = () =>
    setResults([...results, { key: '', operation: '' }]);

  const onChangeResultSelect = (e, index) => {
    const { value } = e.target;

    setResults(
      results.map((item, i) => {
        if (i === index) {
          item.operation = value;
          return item;
        }
        return item;
      })
    );
  };

  const onChangeResultInput = (e, index) => {
    const { value } = e.target;
    setResults(
      results.map((item, i) => {
        if (i === index) {
          item.key = value;
          return item;
        }
        return item;
      })
    );
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (
      !challengeName.input ||
      !challengeImg.input ||
      !description.input ||
      !startDate ||
      !endDate ||
      !excerpt.input ||
      !excerptEs.input
    ) {
      setInputErrors();
      return;
    }

    setLoading(true);

    const challenge = {
      active: false,
      description: description.input,
      description_es: descriptionEs.input,
      title: challengeName.input,
      title_es: challengeNameEs.input,
      excerpt: excerpt.input,
      excerpt_es: excerptEs.input,
      photoURL: challengeImg.input,
      startDate: Date.parse(
        moment(
          `${startDate.format('MM-DD-YYYY')} ${startTime.format('hh:mm a')}`,
          'MM-DD-YYYY hh:mm a'
        ).format()
      ),
      endDate: Date.parse(
        moment(
          `${endDate.format('MM-DD-YYYY')} ${endTime.format('hh:mm a')}`,
          'MM-DD-YYYY hh:mm a'
        ).format()
      ),
      rankedType: soloQAndFLex ? 'SOLOQ_AND_FLEX' : 'SOLOQ',
      challengeType: challengeType.toUpperCase(),
      prizes,
      gallery,
      rules_es: rulesEs,
      rules,
      coins: coins.input,
      requirements: {
        conditions: setConditions(),
        results,
      },
      createdBy: {
        email,
      },
    };

    try {
      // await createChallenge(challenge);
      const res = await createChallengeService(challenge);
      console.log('created', res);
      setLoading(false);
      toast.success('Challenge Created Successfully', {
        position: toast.POSITION.TOP_RIGHT,
      });
      navigate('/');
    } catch (error) {
      setLoading(false);
      toast.error('Something went wrong on create the challenge', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  return (
    <PageWrapper>
      <form onSubmit={onSubmit}>
        <FlexJustify>
          <PageTitle>New challenge</PageTitle>
          <ButtonDashboard type="submit">
            {loading ? (
              <ReactLoading type="spin" color="#fff" height={25} width={25} />
            ) : (
              'Save Changes'
            )}
          </ButtonDashboard>
        </FlexJustify>
        <PageSection>Basic Info</PageSection>
        <SectionWrapper style={{ height: 200 }}>
          <Input
            width={400}
            type="text"
            text="Challenge Title"
            value={challengeName.input}
            placeholder="Title"
            onChange={challengeName.onChange}
            error={challengeName.error}
          />

          <Input
            width={400}
            type="text"
            text="Challenge Title ES"
            value={challengeNameEs.input}
            placeholder="Title ES"
            onChange={challengeNameEs.onChange}
            error={challengeNameEs.error}
          />
          <Input
            type="text"
            text="Challenge Image"
            value={challengeImg.input}
            onChange={challengeImg.onChange}
            error={challengeImg.error}
            placeholder="image url"
          />
          <Select
            text="Challenge Type"
            value={challengeType}
            onChange={(e) => setChallengeType(e.target.value)}
          >
            <option value="" defaultChecked disabled>
              Select Type
            </option>
            <option value="free" defaultChecked>
              Free
            </option>
            <option value="premium">Premium</option>
          </Select>
        </SectionWrapper>

        <SectionWrapper>
          <Textarea
            width={300}
            height={150}
            style={{ marginRight: 30, borderRadius: 5 }}
            name="textarea-1"
            placeholder="Description "
            value={description.input}
            onChange={description.onChange}
          />

          <Textarea
            width={300}
            height={150}
            style={{ marginRight: 30, borderRadius: 5 }}
            name="textarea-2"
            placeholder="Description ES"
            value={descriptionEs.input}
            onChange={descriptionEs.onChange}
          />
          <ColumnWrapper>
            <FlexJustify style={{ width: 360 }}>
              <DatePicker
                id="startDate"
                date={startDate}
                setDate={setStartDate}
                text="Start Date"
              />
              <TPicker
                text="Start Time"
                value={startTime}
                onChange={(time) => setStartTime(time)}
              />
            </FlexJustify>
            <br />
            <FlexJustify style={{ width: 360 }}>
              <DatePicker
                id="endDate"
                date={endDate}
                setDate={setEndDate}
                text="End Date"
              />
              <TPicker
                text="End Time"
                value={endTime}
                onChange={(time) => setEndTime(time)}
              />
            </FlexJustify>
          </ColumnWrapper>
        </SectionWrapper>
        <br />
        <SectionWrapper>
          <Textarea
            width={300}
            height={100}
            style={{ marginRight: 30, borderRadius: 5 }}
            name="textarea-1"
            placeholder="Excerpt  "
            value={excerpt.input}
            onChange={excerpt.onChange}
          />
          <Textarea
            width={300}
            height={100}
            style={{ marginRight: 30, borderRadius: 5 }}
            name="textarea-1"
            placeholder="Excerpt ES"
            value={excerptEs.input}
            onChange={excerptEs.onChange}
          />
        </SectionWrapper>
        <PageSection>Media</PageSection>
        <SectionWrapper>
          <div>
            <RenderFields
              placeholder="media url"
              onAdd={onAddItem}
              items={gallery}
              setItem={setGallery}
            />
            <ItemsContainer style={{ width: 500 }}>
              {gallery.map((media, i) => (
                <ItemList key={i}>
                  {media}
                  <IconContainer style={{ marginRight: 0 }}>
                    <IoMdTrash
                      color="#fff"
                      size={20}
                      onClick={() => onDeleteItem(i, setGallery, gallery)}
                    />
                  </IconContainer>
                </ItemList>
              ))}
            </ItemsContainer>
          </div>
        </SectionWrapper>
        <PageSection>Rules & prizes</PageSection>
        <SectionWrapper>
          <div style={{ marginRight: 30 }}>
            <RenderFields
              text="Rules"
              onAdd={onAddItem}
              placeholder="rule"
              items={rules}
              setItem={setRules}
            />
            <ItemsContainer>
              {rules.map((rule, i) => (
                <ItemList key={i}>
                  {rule}
                  <IconContainer style={{ marginRight: 0 }}>
                    <IoMdTrash
                      color="#fff"
                      size={20}
                      onClick={() => onDeleteRuleItem(i)}
                    />
                  </IconContainer>
                </ItemList>
              ))}
            </ItemsContainer>
          </div>
          <div>
            <RenderFields
              text="Prizes"
              onAdd={onAddPrize}
              placeholder="prize"
              withExtraInput
            />
            <ItemsContainer>
              {prizes.map((prize, i) => (
                <ItemList key={i}>
                  <span># {i + 1}</span>
                  <InputField
                    style={{ width: 100, margin: 0 }}
                    value={prize.type}
                    onChange={(e) => onChangePrizeItems(e, i, 'type')}
                  />
                  <InputField
                    style={{ width: 100, margin: 0 }}
                    value={prize.value}
                    onChange={(e) => onChangePrizeItems(e, i, 'value')}
                  />
                  <IconContainer style={{ marginRight: 0 }}>
                    <IoMdTrash
                      color="#fff"
                      size={20}
                      onClick={() => onDeletePrizeItem(i)}
                    />
                  </IconContainer>
                </ItemList>
              ))}
            </ItemsContainer>
          </div>
          <div style={{ marginRight: 30 }}>
            <RenderFields
              text="Rules ES"
              onAdd={onAddItem}
              placeholder="rule"
              items={rulesEs}
              setItem={setRulesEs}
            />
            <ItemsContainer>
              {rulesEs.map((rule, i) => (
                <ItemList key={i}>
                  {rule}
                  <IconContainer style={{ marginRight: 0 }}>
                    <IoMdTrash
                      color="#fff"
                      size={20}
                      onClick={() => onDeleteItem(i, setRulesEs, rulesEs)}
                    />
                  </IconContainer>
                </ItemList>
              ))}
            </ItemsContainer>
          </div>
        </SectionWrapper>
        <PageSection>Requirements</PageSection>
        <FlexItems>
          <div style={{ width: 400 }}>
            <ChallengeLabel>Conditions</ChallengeLabel>
            <Checkbox
              id="matchcwin"
              labelText="Force match to win"
              value={matchWin}
              change={() => setMatchWin(!matchWin)}
            />
            <br />
            <Checkbox
              id="soloQAndFlex"
              labelText="SoloQ and Flex"
              value={soloQAndFLex}
              change={() => setSoloQAndFLex(!soloQAndFLex)}
            />
            <Select
              width={200}
              style={{ marginTop: 20 }}
              text="Champions"
              value={champsOption}
              onChange={(e) => setChampsOption(e.target.value)}
              disabled={selectedChamps.length >= 1}
            >
              <option value="allowed" defaultChecked>
                Allowed
              </option>
              <option value="blocked">Blocked</option>
            </Select>
            <SectionWrapper>
              <SectionWrapper style={{ marginTop: 30 }}>
                <FlexColumn style={{ marginRight: 30 }}>
                  <ChallengeLabel>Search Champions</ChallengeLabel>
                  <SearchChamp
                    placeholder="Search a champion"
                    onSelect={onSelectChamp}
                  />
                </FlexColumn>
              </SectionWrapper>
            </SectionWrapper>
            <FlexColumn>
              <ChallengeLabel>Selected champions</ChallengeLabel>
              <ItemsContainer style={{ width: 250 }}>
                {selectedChamps.map((champ, i) => (
                  <ItemList key={i}>
                    <AlignedItems>
                      <span style={{ marginRight: 10 }}># {i + 1}</span>
                      <ImgChamp src={champ.champIcon} /> {champ.name}
                    </AlignedItems>
                    <IconContainer style={{ marginRight: 0 }}>
                      <IoMdTrash
                        color="#fff"
                        size={20}
                        onClick={() =>
                          onDeleteChamp(i, setSelectedChamps, selectedChamps)
                        }
                      />
                    </IconContainer>
                  </ItemList>
                ))}
              </ItemsContainer>
            </FlexColumn>
            <FlexColumn>
              <ChallengeLabel>Coins required to Join</ChallengeLabel>
              <InputField
                style={{ marginTop: '0', width: 150 }}
                type="number"
                placeholder="Coins"
                value={coins.input}
                onChange={coins.onChange}
              />
            </FlexColumn>
          </div>
          <div style={{ width: '100%', maxWidth: 500 }}>
            <ChallengeLabel>Results</ChallengeLabel>
            <ItemsContainer style={{ width: '100%' }}>
              {results.map((result, i) => (
                <ItemList
                  key={i}
                  style={{ background: 'unset', padding: '1rem 0' }}
                >
                  <Input
                    style={{ height: 62 }}
                    text="Key"
                    type="text"
                    placeholder="Enter a key"
                    value={result.key}
                    onChange={(e) => onChangeResultInput(e, i)}
                  />
                  <Select
                    value={result.operation}
                    text="Operation"
                    onChange={(e) => onChangeResultSelect(e, i)}
                  >
                    <option value="" defaultChecked disabled>
                      Select Operation
                    </option>
                    <option value="MAX">Max</option>
                    <option value="MIN">Min</option>
                  </Select>
                  <IconContainer style={{ marginRight: 0 }}>
                    <IoMdTrash
                      color="#fff"
                      size={20}
                      onClick={() => onDeleteItem(i, setResults, results)}
                    />
                  </IconContainer>
                </ItemList>
              ))}
            </ItemsContainer>
            <ButtonDashboard
              type="button"
              style={{ padding: '1rem 3rem' }}
              onClick={onAddResult}
            >
              add new result
            </ButtonDashboard>
          </div>
        </FlexItems>
      </form>
    </PageWrapper>
  );
};

export default NewChallenge;
