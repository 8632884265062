import axios from 'axios';

function resetToken() {
  return axios.create({
    baseURL: `https://securetoken.googleapis.com/v1/token?key=${process.env.REACT_APP_FIREBASE_API_KEY}`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

const config = {
  baseURL: process.env.REACT_APP_END_POINT_URL,
  headers: {
    'content-type': 'application/json',
  },
  responseType: 'json',
};

const axiosApiInstance = axios.create(config);

const axiosApiFileInstance = axios.create({
  baseURL: process.env.REACT_APP_END_POINT_URL,
  headers: {
    'content-disposition': 'attachment;filename=report.xls',
    'content-type': 'application/vnd.ms-excel',
  },
});

axiosApiInstance.interceptors.request.use(
  async (cfg) => {
    const token = await sessionStorage.getItem('pokyDashboardAuthToken');
    if (token) {
      cfg.headers.Authorization = `Bearer ${token}`;
    }
    return cfg;
  },
  (error: any) => {
    Promise.reject(error);
  }
);

axiosApiInstance.interceptors.response.use(
  (response) => response,
  async function (error) {
    const originalRequest = error.config;
    if (!error.response) {
      return Promise.reject('Network Error');
    }
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = sessionStorage.getItem('pokyDashboardRefreshToken');
      const { data } = await resetToken().post(
        '',
        `grant_type=refresh_token&refresh_token=${refreshToken}`
      );
      originalRequest.headers['Authorization'] = `Bearer ${data.id_token}`;

      sessionStorage.setItem('pokyDashboardAuthToken', data.id_token);
      sessionStorage.setItem('pokyDashboardRefreshToken', data.refresh_token);

      return axios(originalRequest);
    }
    return Promise.reject(error);
  }
);

export const api = () => axiosApiInstance;

export const publicApi = () =>
  axios.create({
    baseURL: process.env.REACT_APP_END_POINT_URL,
  });
